<template>
  <SingleComponent
    :loading="loading"
    :mode="mode"
    @add-button="addButton"
    @edit-button="editButton"
  >
    <v-text-field
      v-model="input.name"
      label="Название"
      :rules="[$validate.required]"
    >
    </v-text-field>
  </SingleComponent>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import SingleComponent from "@/components/SingleComponent";
import seo from "@/model/seo";
export default {
  components: { SingleComponent },
  data() {
    return {
      loading: true,
      mode: "Добавить",
      input: {},
    };
  },
  computed: {
    ...mapGetters({
      SEO: "Seo/STATE",
    }),
  },
  async created() {
    let body = {};
    await this.getTags();
    if (this.$route.params.id) {
      this.mode = "Сохранить";
      let arr = this.SEO.tagsAll.map((x) => {
        return x.id;
      });
      body = this.SEO.tagsAll[arr.indexOf(JSON.parse(this.$route.params.id))];
    }
    let model = new seo();
    this.input = model.setTag(this.mode, body);
    this.loading = false;
  },
  methods: {
    ...mapActions({
      getTags: "Seo/GET_TAGS",
      addSeo: "Seo/SAVE_SEO_TAG",
      editSeo: "Seo/UPDATE_SEO_TAG",
    }),
    async addButton() {
      this.loading = true;
      let response = await this.addSeo(this.input);
      if (response.type === "success") {
        this.$router.push("/seo");
      } else {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: response.message,
        });
      }
      this.loading = false;
    },
    async editButton() {
      this.loading = true;
      let response = await this.editSeo(this.input);
      if (response.type === "success") {
        this.$router.push("/seo");
      } else {
        this.$notify({
          group: "app",
          type: "error",
          title: "Ошибка",
          text: response.message,
        });
      }
      this.loading = false;
    },
  },
};
</script>
